import React from "react";
import {Link} from "react-router-dom";
import BasePage from "../comp/PageBase";


const FourOhFour = () => {
    return <div className={"c-prose"}>
        <h3 className={"c-floating"}>What. The. Hell.</h3>
        <p>
            Hey, Buddy! ...can't you read? There are <Link to="/secret/"><b>no other pages</b></Link> on this site.
        </p>
        <p>
            Just the index. Otherwise, that's a <b>404</b>.
        </p>
    </div>
}

// eslint-disable-next-line import/no-anonymous-default-export
export default () => { return <BasePage child={<FourOhFour />}/>; };