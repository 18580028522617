import React from 'react';
import ReactDOM from 'react-dom/client';

import MainPage from './routes/MainPage';
import FourOhFour from './routes/404';
import Secret from "./routes/Secret";

import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import BlogPage from "./routes/BlogPage";

const router = createBrowserRouter([
    {
        path: "/",
        element: <MainPage />,
        errorElement: <FourOhFour />
    },
    {
        path: "/blog",
        element: <BlogPage />
    },
    {
        path: "/secret",
        element: <Secret />
    }
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);