import React, {ReactNode} from 'react';
import '../style/App.scss';

import {Link} from "react-router-dom";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({child}: {child: ReactNode}) => {
    return <>
        <div className={"c-header"}>
            <Link to={'/'}>
                <img src="https://cdn.thissell.me/signature.svg" className={"c-signature"} alt="Jackson T."/>
            </Link>
        </div>
        <div className={"l-container"}>
            {child}
        </div>
        <div className={"c-contact"}>
            Get in Touch: <b>jackson@thissell.me</b>
        </div>
    </>;
}