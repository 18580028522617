import React from "react";

import BasePage from "../comp/PageBase";

import BlogPost from "../comp/BlogPost";

const BlogPage = () => {

    return <div>
        <BlogPost>
            Hello.
        </BlogPost>
    </div>
}

// eslint-disable-next-line import/no-anonymous-default-export
export default () => { return <BasePage child={<BlogPage />}/>; };