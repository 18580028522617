import BasePage from "../comp/PageBase";
import React from "react";


const Secret = () => {
    return <div className={"c-prose"}>
        <p> lol haha yeah you caught me </p>
    </div>
}

export default () => { return <BasePage child={<Secret />}/>; };