import BasePage from "../comp/PageBase";

const MainPage = () => {

    return <div className={"c-prose text-justify"}>
        <h2>Welcome to my Internet House.</h2>
        <p>
            Hello! My name is Jackson Thissell. I go to the University of Maine as a first year
            Electrical and Computer Engineering PhD and Global Policy MBA student. My PhD specializes in
            a holistic approach to explainable, embodied machine intelligence.
        </p>
        <div className={'c-bigbox'}>
            <span className={'c-bigbox__title'}>IMPORTANT INFO!</span>
            <p>
                If you are a student in one of my recitations for MAT 126 - Calculus I, my office hours are <b>2-3pm
                Mon/Wed, 120 Neville Hall</b> (the Math Lab).
            </p>
            <p>
                If you are an ECE graduate student curious about grant opportunities or representation, or otherwise
                curious about the roles of the <a href={"https://umaine.edu/gsg/"}>Graduate Student Government</a> senate,
                contact me via my university email: <b>jackson.thissell@maine.edu</b>.
            </p>
        </div>
        <p>
            A widely cited, non-partisan poll concluded that I don't bite:
            find me on <a href={'https://www.instagram.com/jaythissell'}>Instagram</a> and <a href={"https://open.spotify.com/user/m82d0r2jpuuiaebfmfkhsym80?si=bca8cc51c1c64313"}>
            Spotify</a>.
        </p>
    </div>
}

// eslint-disable-next-line import/no-anonymous-default-export
export default () => { return <BasePage child={<MainPage />}/>; };