import ReactMarkdown from "react-markdown";
import RehypeMathJax from "rehype-mathjax/svg";
import RehypeHighlight from "rehype-highlight";
import RemarkMath from "remark-math";
import React, {useEffect} from "react";

import hljs from 'highlight.js'

import "highlight.js/styles/github-dark.css";

const Post = ({ children }: {children: any}) => {
    useEffect(()=>{
        hljs.initHighlighting();
    },[]);

    return <div className={'c-blog-post'}>
        <div className={'c-blog-metadata'}>
            <p className={'c-blog-title'}>Making Sense of the Mobius Function</p>
            <p className={'c-blog-date'}>by Jackson Thissell. Published on July 19, 2004</p>
        </div>
        <div className={"c-prose c-blog-prose"}>
            <ReactMarkdown
                rehypePlugins={[RehypeMathJax, RehypeHighlight]}
                remarkPlugins={[RemarkMath]}
            >
                {children}
            </ReactMarkdown>
        </div>
    </div>
}

export default Post;